import React, { Component } from "react";





class MediaDis extends Component{
    
    render(){
    return (
  
        <div>
        </div>

    );
}}

export default MediaDis;