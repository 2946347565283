import React from 'react'


import Team1 from '../components/Assist/images/Soom Team Pictures/IMG-20201220-WA0012.jpg'
import Team2 from '../components/Assist/images/Soom Team Pictures/Akbar Profile Picture.jpg'
import Team3 from '../components/Assist/images/Soom Team Pictures/Fakhry Profile Picture.jpg'
import Team4 from '../components/Assist/images/Soom Team Pictures/Ubaid Profil Picture.jpg'
import Team5 from '../components/Assist/images/Soom Team Pictures/avatar+person+profile+user+icon-1320166578424287581.png'
import Team6 from '../components/Assist/images/Soom Team Pictures/WhatsApp Image 2021-01-19 at 4.32.08 PM.jpg'



function About() {
    return (


<div className="w3l-about-breadcrum-3" id="About">
  <div className="breadcrum-bg">
  
    <div className="container py-5">
    
      <h2 className="my-3">About Us</h2>
    </div>
  </div>
  <div>
  
</div>
<div className="w3l-content-with-photo-4" id="about">
  
        <div className="container py-md-3">
            <div className="cwp4-two row">
               
                <div className="cwp4-text col-lg-12">
				<br /><br /><br /><br />
                        <h3>Our network</h3>
                    
                    <ul className="cont-4">
                        <li><span className="fa fa-check"></span>Soom Oil is the authorized distributor of Aero Shell products in KSA, Jordan & Bahrain.</li>
                        <li><span className="fa fa-check"></span>Our offices are in Jeddah and Riyadh.</li>
                        <li><span className="fa fa-check"></span>Our warehouses are in Jeddah and Dubai.</li>
                        <li><span className="fa fa-check"></span>Our Aeroshell products range consists of Aviation Turbine oils, Greases, Piston Engine oil, Hydraulic Fluids and specialty Products.</li>
						<li><span className="fa fa-check"></span>All our products meet and exceed USA, UK, French, Military specifications, and OEM approvals.</li>
                        <li><span className="fa fa-check"></span>Our customers are Airlines, MRO’s, Flying Schools, Medical Evacuation, Air Force, Civil Defense (Helicopter), Red Crescent, Cargo, and a wide range of customers in the oil & gas and Industrial sectors. </li>

					
					</ul>
					<br /><br /><br /><br />
                </div>
               
            </div>
        </div>
    
</div>


<div className="w3l-about ">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
    <h1 className="head text-white">Mission</h1>
    <p className="my-3 head text-white">Our mission at Soom Oil is to cater to the needs of our customers. We aim to offer more than a product or service; our goal is to build a strong and reliable partnership.</p>
      
    </div>
	<div className="heading text-center mx-auto">
    <h1 className="head text-white">Vision</h1>
    <p className="my-3 head text-white"> Soom Oil aspires to be the largest Aviation Lubricants distributor in the region.</p>
      
    </div>

 </div>
</div>
 </div>


 <div className="w3l-team-main">
		<div className="team py-5">
			<div className="container py-lg-5">
				<div className="heading text-center mx-auto">
					<h3 className="head">Our Team</h3>
					
					  
					</div>
					<div className="row team-row pt-3 mt-5">

                    <div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team6} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Eng. Murad Bamane </h4>
												<p>CEO</p>
												
										</div>
									</div>
							</div>
						
							
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team1} alt="" className="img-fluid team-image"   style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Eng. Abed Nadeem </h4>
												<p>Sales Manager</p>
												
										</div>
									</div>
							</div>
						
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
											<img src={Team2} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Akbar Ali</h4>
												<p>Sales Representative</p>
												
										</div>
									</div>
								
							</div>

                            <div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team4} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">UbaidUllah Khane </h4>
												<p>Logistics Manager</p>
												
										</div>
									</div>
								
							</div>
						
							
						
							<div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src= {Team5} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Mohammed Amin</h4>
												<p>Finance Manager</p>
												
										</div>
									</div>
							</div>
						
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team3} alt="" className="img-fluid team-image" style={{ width: '100%', maxHeight: '250px',}} />
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Mohammed Fakhry</h4>
												<p>General Accountant</p>
												
										</div>
									</div>
								
							</div>
				
									</div>
							</div>
							
				</div>
			</div>
	</div>




    
    )
}

export default About
