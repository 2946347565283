import React from 'react'
import pic1 from '../components/Assist/images/slaider/Copy of SHELL20026_Thermal_Stress_16x9.jpg'
import pic4 from '../components/Assist/images/slaider/DYNAMIC_CONTENT_16X9.jpg'
import pic6 from '../components/Assist/images/slaider/SHELL20026_Lower_Coking_16x9.jpg'
import pic7 from '../components/Assist/images/slaider/SHELL20026_Protection_during_winter_16x9.jpg'
import pic8 from '../components/Assist/images/slaider/SHELL20026_Static_Corrosion_16x9.jpg'
import pic10 from '../components/Assist/images/slaider/SHELL20026_SUN-SAFE_16x9.jpg'
import pic11 from '../components/Assist/images/slaider/SHELL20026_Withstand_Heat_16x9.jpg'
import pic12 from '../components/Assist/images/slaider/SHELL20044_Grease_33_All-round_16x9.jpg'
import pic13 from '../components/Assist/images/slaider/SHELL20044_Grease_58_Bearings_16x9.jpg'






import Carousel from 'react-bootstrap/Carousel'
import part1 from '../components/Assist/images/Saudia_Aerospace_Engineering_Industires_(Sael)_logo.png';
import part2 from '../components/Assist/images/alsalam.jpg';
import part3 from '../components/Assist/images/skyprime.jpg';
import part4 from '../components/Assist/images/THC.png';
import part5 from '../components/Assist/images/MEE.jpg';
import part6 from '../components/Assist/images/sans.jpg';
import part7 from '../components/Assist/images/rabighwing.jpg';
import part8 from '../components/Assist/images/sch.png';
import part9 from '../components/Assist/images/petro.jpg';
import part10 from '../components/Assist/images/Raytheon-Logo.jpg';
import part11 from '../components/Assist/images/DynCorp-International-Logo.jpg';
import part12 from '../components/Assist/images/sec2.jpg';



import Team1 from '../components/Assist/images/Soom Team Pictures/IMG-20201220-WA0012.jpg'
import Team2 from '../components/Assist/images/Soom Team Pictures/Akbar Profile Picture.jpg'
import Team3 from '../components/Assist/images/Soom Team Pictures/Fakhry Profile Picture.jpg'
import Team4 from '../components/Assist/images/Soom Team Pictures/Ubaid Profil Picture.jpg'
import Team5 from '../components/Assist/images/Soom Team Pictures/avatar+person+profile+user+icon-1320166578424287581.png'
import Team6 from '../components/Assist/images/Soom Team Pictures/WhatsApp Image 2021-01-19 at 4.32.08 PM.jpg'






import PDFfile from '../components/Assist/PDF/Shell Distubitor certificate.pdf'



export default function Home() {
    return (
        <div id="home">
    
   
	
    <Carousel className="carousel1" indicators={false} fade={true}>
  <Carousel.Item >
    <img 
    
      className="responsive "
      src={pic1} 
      alt="First slide"
    />
  </Carousel.Item>

  

 
  <Carousel.Item>
    <img 
      className="responsive"
      src={pic4} 
      alt="First slide"
    />
  </Carousel.Item>

 

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic6} 
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic7} 
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic8} 
      alt="First slide"
    />
  </Carousel.Item>

  

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic10} 
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic11} 
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic12} 
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img 
      className="responsive"
      src={pic13} 
      alt="First slide"
    />
  </Carousel.Item>
 
</Carousel>



  
 
<div className="w3l-content-with-photo-4" id="about">
  
  <div className="container py-md-3">
      <div className="cwp4-two row">
         
          <div className="cwp4-text col-lg-12">
          <br /><br /><br /><br />
                  <h3>Our network</h3>
              
              <ul className="cont-4">
                  <li><span className="fa fa-check"></span>Soom Oil is the authorized distributor of Aero Shell Products in KSA, Jordan & Bahrain.</li>
                  <li><span className="fa fa-check"></span>Our offices are in Jeddah and Riyadh.</li>
                  <li><span className="fa fa-check"></span>Our warehouses are in Jeddah and Dubai.</li>
                  <li><span className="fa fa-check"></span>Our Aeroshell products range consists of Aviation Turbine oils, Greases, Piston Engine oil, Hydraulic Fluids and specialty Products.</li>
                  <li><span className="fa fa-check"></span>All our products meet and exceed USA, UK, French, Military specifications, and OEM approvals.</li>
                  <li><span className="fa fa-check"></span>Our customers are Airlines, MRO’s, Flying Schools, Medical Evacuation, Air Force, Civil Defense (Helicopter), Red Crescent, Cargo, and a wide range of customers in the oil & gas and Industrial sectors. </li>

              
              </ul>
              <br /><br /><br /><br />
          </div>
         
      </div>
  </div></div>


<div className="w3l-about ">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
    <h1 className="head text-white">Mission</h1>
    <p className="my-3 head text-white">Our mission at Soom Oil is to cater to the needs of our customers. We aim to offer more than a product or service; our goal is to build a strong and reliable partnership.</p>
      
    </div>
    <br>

    </br>
	<div className="heading text-center mx-auto">
    <h1 className="head text-white">Vision</h1>
    <p className="my-3 head text-white"> Soom Oil aspires to be the largest Aviation Lubricants distributor in the region.</p>
      
    </div>

 </div>
</div>
 </div>


 <div className="w3l-about61">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
  <h1 >Customers Testimonial </h1>
   
   <br>

   </br>
     
       <a className="btn-theme2" href='/Customers_Testimonial' > See Testimonial</a> 
    </div>
	

 </div>
</div>
 </div>

  <div className="w3l-about6">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
  <h1 >Our Products </h1>
   <br></br>
      <p> PISTON ENGINE OILS</p>
      <p> TURBINE ENGINE OILS </p>
      <p> AVIATION GREASES </p>
      <p> HYDRAULIC FLUIDS </p>
      <p> SPECIALIST FLUIDS</p>
      <br></br>
      <a className="btn-theme2"  href='/Proudcts' > Click to  See Our Products</a> 

    </div>

    

    
 </div>
</div>
 </div>



 <div className="w3l-team-main">
		<div className="team py-5">
			<div className="container py-lg-5">
				<div className="heading text-center mx-auto">
					<h3 className="head">Our Team</h3>
					
					  
					</div>
					<div className="row team-row pt-3 mt-5">

                    <div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team6} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Eng. Murad Bamane </h4>
												<p>CEO</p>
												
										</div>
									</div>
							</div>
						
							
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team1} alt="" className="img-fluid team-image"   style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Eng. Abed Nadeem </h4>
												<p>Sales Manager</p>
												
										</div>
									</div>
							</div>
						
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
											<img src={Team2} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Akbar Ali</h4>
												<p>Sales Representative</p>
												
										</div>
									</div>
								
							</div>

                            <div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team4} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">UbaidUllah Khane </h4>
												<p>Logistics Manager</p>
												
										</div>
									</div>
								
							</div>
						
							
						
							<div className="col-lg-3 col-sm-6 team-wrap mt-sm-0 pt-sm-0 mt-4 pt-2">
			
									<div className="team-info">
											<div className="column position-relative">
												<img src= {Team5} alt="" className="img-fluid team-image"  style={{ width: '100%', maxHeight: '250px',}}/>
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Mohammed Amin</h4>
												<p>Finance Manager</p>
												
										</div>
									</div>
							</div>
						
				
							<div className="col-lg-3 col-sm-6 team-wrap mt-lg-0 pt-lg-0 mt-4 pt-2">
									<div className="team-info">
											<div className="column position-relative">
												<img src={Team3} alt="" className="img-fluid team-image" style={{ width: '100%', maxHeight: '250px',}} />
											</div>
											<div className="column-btm">
												<h4 className="name-pos">Mohammed Fakhry</h4>
												<p>General Accountant</p>
												
										</div>
									</div>
								
							</div>
				
									</div>
							</div>
							
				</div>
			</div>
 
  <div className="w3l-about5 ">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
  <h1 >AUTHORISED AEROSHELL DISTRIBUTOR CRETIFICATE</h1>
    <h4 className="my-3 head text-white">Authorized Distributor of Shell Aviation lubricants for </h4>
    <br></br>
      <p> SAUDI ARABIA</p>
      <p> BAHRAIN </p>
      <p> JORDAN </p>
      <br></br>
       <a className="btn-theme2" href={PDFfile} download >   Download cretificate </a> 
    </div>
	

 </div>
</div>
 </div>

 <div className="w3l-about61">
<div className="skills-bars py-5">
 <div className="container py-md-3">
  <div className="heading text-center mx-auto">
  <h1 >Product Applications</h1>
   
   <br>

   </br>
     
       <a className="btn-theme2" href='/Product_Applications' > See Product Applications</a> 
    </div>
	

 </div>
</div>
 </div>



 

<div className="w3l-services2" id="stats">

            <div className="features-with-17_sur py-5">

                   <div className="container py-md-3">
                   <h6>Our Customers:</h6>
                     <div className="row pt-lg-5 mt-lg-3">

                     <div className="fea-gd-vv text-center row  ">
     
     
                      
                      <div  className="col-lg-2 col-md-6 mt-5">	
                          <div className="float-top">	
                             <img src={part1}
                            
                              className="img-responsive" alt="Prpoudcts img"/>
                              <div className="float-lt feature-gd">	
                                     
                                         
                                      </div>
                                      
                                  </div>
                      </div>

     
     
                      
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part2}
           
             className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>
                     
                 </div>
     </div>

     
     
                      
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part3} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>
                     
                 </div>
     </div>

     
     
                      
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part4}
            
             className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>
                     
                 </div>
     </div>

 
     
     
                      
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part5} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>
                     
                 </div>
     </div>

     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part6} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>
                     
                 </div>
     </div>

     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part7} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part8} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>

     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part9} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>

     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part10} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part11} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>
     <div  className="col-lg-2 col-md-6 mt-5">	
         <div className="float-top">	
            <img src={part12} 
            
            className="img-responsive" alt="Prpoudcts img"/>
             <div className="float-lt feature-gd">	
                    
                        
                     </div>

                     
                     
                 </div>
     </div>
</div>
                       
                            </div>
                            </div>
                            </div>
                            </div>

                            

                            <div className="w3l-contacts-2" id="contact">     
	<div className="contacts-main">
		<div className="contant11-top-bg py-5">
			<div className="container py-md-3  lll">
				<div className="d-grid contact section-gap">
                <div className="heading  mx-auto">
               <h3 className="head text-center">Contact Us</h3>
					<div className="contact-info-left d-grid text-center">
						<div className="contact-info">
							<span className="fa fa-map-marker" aria-hidden="true"></span>
							<h4>Address</h4>
							<p>Abi Habeeb Al Ansary st, Al Marwa Dist. – Jeddah 23541 
							Abdullah BaGhaffar Center, Office No. 12, 6th Floor</p>
						</div>
						
						<div className="contact-info">
							<span className="fa fa-mobile" aria-hidden="true"></span>
							<h4>Phone</h4>
							<p><a href="tel:+966 551 577 772">+966 551 577 772</a></p>
							<p><a href="tel:++966 122 612 806">+966 122 612 806</a></p>
						</div>
						<div className="contact-info">
							<span className="fa fa-envelope-o" aria-hidden="true"></span>
							<h4>Mail</h4>
							<p><a href="mailto:Info@Soomoil.com" className="email">Info@Soomoil.com</a></p>
							<p><a href="mailto:Nadeem@Soomoil.com" className="email">Nadeem@Soomoil.com</a></p>
						</div>
						
					</div>
                    </div>
				</div>
			</div>
            <div className="container">
            <div className="contact-right">
                    <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927.3416944765676!2d39.19552592918859!3d21.6106268991054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47f883f2103b6ea!2z2LTYsdmD2Kkg2KPYqNmG2KfYoSDYudio2K_Yp9mE2YTZhyDYqNin2LrZgdin2LEg2KfZhNmF2K3Yr9mI2K_YqQ!5e0!3m2!1sen!2ssa!4v1609179660511!5m2!1sen!2ssa" frameBorder="0" style={{ border : 0}} allowFullScreen=""></iframe>
					
            </div>
				
			</div>
		</div>
		
			
		
      
		</div>
		</div>

                       
                            
                        </div>
                    
  
      
    )
}
