import React from 'react';

import  'react-router-dom';
import ProudctItem from './ProudctItem';

import PItem from './ProudctsData';

import { CategoryList } from './ProudctsData';






 class Proudcts extends React.Component {

  state = {
    PItemFilter: PItem,
  }

   filterItems(CategoryItem){
     if (CategoryItem === 'ALL'){
       this.setState({PItemFilter: PItem});
     }else {
       let PItemFilter = PItem.filter(key => {return (key.category === CategoryItem)} )
      
        this.setState({PItemFilter:PItemFilter})
     }
   }

  render(){
    return (
<div className="w3l-about-breadcrum" id="Proudcts">
  <div className="breadcrum-bg">
    <div className="container py-5">
      <h2 className="my-3">Proudcts</h2>
    </div>
  </div>






  <div className="w3l-features-8">
           <div className="features py-5" id="features">
			<div className="container py-md-3">
				
				<div className="heading text-center mx-auto">
					<h3 className="head">Our Products</h3>
					<p className="my-3 head"> 
          </p>
					  
				  </div>


          
          
  
        <div className="fea-gd-vv text-center  mt-5 pt-3">
         
          <div className="col-lg-12 col-md-6 col-sm-0 col-xs-0"  >
          {
           CategoryList && CategoryList.map( keyItem  => 
                       
              <button  type="button" style={{ marginRight : '10px', marginTop: '5px'}} className="btn-theme2" key={keyItem} onClick={()=>this.filterItems(keyItem)}>{keyItem}</button>

            )
        }
          </div>
        </div>

          <ProudctItem PItem = {this.state.PItemFilter} ></ProudctItem>

		    </div>
       



          </div>
     
</div>
 
</div>
    )
  }
 }

 export default Proudcts;
