
import React from 'react';
import {Navbar, Nav,NavDropdown} from 'react-bootstrap'
//import { Link, animateScroll as scroll } from "react-scroll";
import pic from './Assist/images/Soomlogo.svg';
import pic2 from './Assist/images/authorized-distributor.png';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { fa} from "@fortawesome/free-solid-svg-icons";


import { Link } from "react-router-dom";




export const NavBar = () =>(
    <div className="navbar2">
     
   <Navbar collapseOnSelect expand="lg"  className="navbar navbar-expand-lg navbar-light py-lg-2 py-2">
   
   <Link className="responsive2 center" to="/">
   <img href="/" src={pic} alt="Your logo" title="SOOM OIL logo" style= {{height :120 ,width :200}}  /> </Link>
  <Navbar.Brand className="navbar-brand ml-15 pr-10"><a className="navbar-brand mr-15" href="/">
        SOOM  OIL 
    </a>
    </Navbar.Brand>
  <Navbar.Toggle className="navbar-toggler  fa fa-bars" aria-controls="responsive-navbar-nav" />
  
  <Navbar.Collapse className="navbar-collapse collapse" id="responsive-navbar-nav">
    <Nav className="mr-auto">
    <ul className="navbar-nav mx-auto center">
          <li className="nav-item">

            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link " to="/About">About Us</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Proudcts" >Products</Link>
          </li>
        

          <li className="nav-item">
          <NavDropdown title="Media Center" id="basic-nav-dropdown">
        <NavDropdown.Item href="/Product_Applications">Product Applications</NavDropdown.Item>
        <NavDropdown.Item href="/Customers_Testimonial">Customers Testimonial</NavDropdown.Item>
        <NavDropdown.Item href="/Media">Events And Video</NavDropdown.Item>
        
      </NavDropdown>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/Contact">Contact Us</Link>
          </li>
        </ul>
    </Nav>
   
  </Navbar.Collapse>
  <img className="responsive2" href="/" src={pic2} alt="Your logo" title="Your logo2"   />
 
  

</Navbar>
 
  </div>
);

