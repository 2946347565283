import React, { Component } from "react";
import ReactPlayer from "react-player"
import  'react-router-dom';


import Data from './MediaData';
import MediaItem from './MediaItem';



class Media extends Component {
    state={
        data : Data
    }
 render(){
    const {data}=this.state

   
    return (

       



        <div className="w3l-contact-breadcrum-4" id="Media">
       
  <div className="breadcrum-bg">
    <div className="container py-5">
      <h2 className="my-3">Media</h2>
    
    </div>
  </div>


  <div className="news py-5">
    <div className="container py-md-3">
        <div className="heading text-center mx-auto">
            <h3 className="head">Video </h3>
                 
          </div>

          <div className="fea-gd-vv text-center row mt-5 pt-3">
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">
      <ReactPlayer
        url="https:www.youtube.com/watch?v=mor6uYn-BSE&feature=youtu.be"
      />
        
              </div>



              
  </div>
      
          

       
  
  

</div>

  </div>
    
	</div>


  








    






<div className="news-1" id="blog">

<div className="news py-5">
    <div className="container py-md-3">
        <div className="heading text-center mx-auto">
            <h3 className="head">Events</h3>
            
              
          </div>






       


          
   
   
          
         
          {data.map(dat=>{
    return <MediaItem key={dat.id} dat={dat} />
  })}
  </div>
  
    
    
	</div>
   

</div>

</div>

    );
}
}
export default Media;
