import React, { Component } from "react";
import  {NavBar } from './components/NavBar';
import {
  Switch,
  Route,
  BrowserRouter,

} from "react-router-dom";

import Home from './components/Home';
import Proudcts from './components/Proudcts';
import About from './components/About';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Media from './components/Media';
import MediaDis from './components/MediaDis';

import Product_Applications from './components/Product_Applications';


import './App.css'
import Customers_Testimonial from './components/Customers_Testimonial';






  










class App extends Component {


  
  render(){


   

  return (
   <>
    
  
   
      
   
  
   
           
            <Switch>
            <BrowserRouter>
    <NavBar />
     
      

   
      
        <Route exact path='/' component = {Home} />
        <Route path='/Proudcts' component = {Proudcts} />
        <Route path='/Media' component = {Media} />
        <Route path='/About' component = {About} />
        <Route path='/Contact' component = {Contact} />
        <Route path="/MediaDis" component={MediaDis} />
        <Route path="/Customers_Testimonial" component={Customers_Testimonial} />
        <Route path="/Product_Applications" component={Product_Applications} />

        <Footer />
       
        </BrowserRouter>
        </Switch>
       
          

    
    




   </>
  );
}
}

export default App;