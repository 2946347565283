import React, { Component } from "react";
import  'react-router-dom';


import test1 from './Assist/images/pdftoimage 2/AeroShell 15W50 Testimonial.jpg'
import test2 from './Assist/images/pdftoimage 2/ASG 33 Testimonial - South African Airways-1.jpg'
import test3 from './Assist/images/pdftoimage 2/ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-1.jpg'
import test4 from './Assist/images/pdftoimage 2/ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-2.jpg'
import test5 from './Assist/images/pdftoimage 2/ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-3.jpg'
import test6 from './Assist/images/pdftoimage 2/ASTO 560, 500 Testimonial - Saudi Arabian Airlines-1.jpg'
import test7 from './Assist/images/pdftoimage 2/Fluid 41 Testimonial - Kenya Airways-1.jpg'


class Test extends Component {
    state={
       
    }
 render(){
   

   
    return (

       



        <div className="w3l-contact-breadcrum-4" id="Media">
       
  <div className="breadcrum-bg">
    <div className="container py-5">
      <h2 className="my-3">Customers Testimonial</h2>
    
    </div>
  </div>


  


  







    <div className="news py-5">
    <div className="container py-md-3">
       

          <div className="fea-gd-vv text-center row mt-5 pt-3">
  
                     

  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test3} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-1</p> </h4>    
                  </div> 
              </div>



              
  </div>
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test4} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-2</p> </h4>    
                  </div> 
              </div>



              
  </div>
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test5} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>ASTO 560, 500 MSL Testimonial - CFM56-5B - Saudi Arabian Airlines-3</p> </h4>    
                  </div> 
              </div>



              
  </div>

  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test6} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>ASTO 560, 500 Testimonial - Saudi Arabian Airlines</p> </h4>    
                  </div> 
              </div>



              
  </div>

  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test7} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>Fluid 41 Testimonial - Kenya Airways</p> </h4>    
                  </div> 
              </div>



              
  </div>
  <div  className="col-lg-4 col-md-6">	
                          <div className="float-top">

                             <img src={test1} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
                              <div className="float-lt feature-gd">	
                                      <h4><p>AeroShell 15W50 Testimonial - Cessna 100,200,350,400 - Avex</p> </h4>    
                                      </div> 
                                      
                                  </div>

                      </div>
      
          

       
  
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">

         <img src={test2} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>ASG 33 Testimonial - South African Airways</p> </h4>    
                  </div> 
              </div>



              
  </div>

</div>

  </div>
    
	</div>








</div>

    );
}
}
export default Test;
