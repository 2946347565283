import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';



import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import { BrowserRouter } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';





ReactDOM.render(
  <BrowserRouter>
    <App />
    </BrowserRouter>,
  document.getElementById('root')
);
