import React from 'react'
//import pic from '../Assist/images/1.jpg';



function Contact() {
    return (
    

        <div className="w3l-contact-breadcrum-2" id= "contact">
  <div className="breadcrum-bg">
    <div className="container py-5">
      
      <h2 className="my-3">Contact Us</h2>
    
    </div>
  </div>



        <div className="w3l-contacts-2" id="contact">     
	<div className="contacts-main">
		<div className="contant11-top-bg py-5">
			<div className="container py-md-3  lll">
				<div className="d-grid contact section-gap">
					<div className="contact-info-left d-grid text-center">
						<div className="contact-info">
							<span className="fa fa-map-marker" aria-hidden="true"></span>
							<h4>Address</h4>
							<p>Abi Habeeb Al Ansary st, Al Marwa Dist. – Jeddah 23541 
							Abdullah BaGhaffar Center, Office No. 12, 6th Floor</p>
						</div>
						
						<div className="contact-info">
							<span className="fa fa-mobile" aria-hidden="true"></span>
							<h4>Phone</h4>
							<p><a href="tel:+966 551 577 772">+966 551 577 772</a></p>
							<p><a href="tel:++966 122 612 806">+966 122 612 806</a></p>
						</div>
						<div className="contact-info">
							<span className="fa fa-envelope-o" aria-hidden="true"></span>
							<h4>Mail</h4>
							<p><a href="mailto:Info@Soomoil.com" className="email">Info@Soomoil.com</a></p>
							<p><a href="mailto:Nadeem@Soomoil.com" className="email">Nadeem@Soomoil.com</a></p>
						</div>
						
					</div>
				</div>
			</div>
            <div className="container">
            <div className="contact-right">
                    <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927.3416944765676!2d39.19552592918859!3d21.6106268991054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47f883f2103b6ea!2z2LTYsdmD2Kkg2KPYqNmG2KfYoSDYudio2K_Yp9mE2YTZhyDYqNin2LrZgdin2LEg2KfZhNmF2K3Yr9mI2K_YqQ!5e0!3m2!1sen!2ssa!4v1609179660511!5m2!1sen!2ssa" frameBorder="0" style={{ border : 0}} allowFullScreen=""></iframe>
					
            </div>
				
			</div>
		</div>
		
			
		
      
		</div>
		</div>
   </div>
      
    )
}

export default Contact
