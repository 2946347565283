import first1 from './Assist/Event/To the Web/20121215_190448.jpg'
import first2 from './Assist/Event/To the Web/20121215_202011.jpg'
import first3 from './Assist/Event/To the Web/20121215_203434.jpg'
import first4 from './Assist/Event/To the Web/20121215_212522.jpg'
import first5 from './Assist/Event/20121215_203359.jpg'



import second1 from './Assist/Event/OneDrive-2020-11-24/20120430_133726.jpg'
import second2 from './Assist/Event/OneDrive-2020-11-24/20120501_091057.jpg'
import second3 from './Assist/Event/OneDrive-2020-11-24/20120501_092049.jpg'
import second4 from './Assist/Event/OneDrive-2020-11-24/20120502_140512.jpg'
import second5 from './Assist/Event/20120501_091118.jpg'



import therid1 from './Assist/Event/fourmila/DSCN0285.JPG'
import therid2 from './Assist/Event/fourmila/DSCN0286.JPG'
import therid3 from './Assist/Event/fourmila/DSCN0290.JPG'
import therid4 from './Assist/Event/fourmila/DSCN0303.JPG'
import therid5 from './Assist/Event/fourmila/DSCN0309.JPG'


import fourth1 from './Assist/Event/Saudi air show2019/IMG_20190312_104142.jpg'
import fourth2 from './Assist/Event/Saudi air show2019/IMG_20190312_111714.jpg'
import fourth3 from './Assist/Event/Saudi air show2019/IMG_20190312_135513.jpg'
import fourth4 from './Assist/Event/Saudi air show2019/IMG_20190312_141230.jpg'
import fourth5 from './Assist/Event/Saudi air show2019/IMG_20190313_133827.jpg'




import fifth1 from './Assist/Event/Shell Distributor Confrence Krakow 2020/01.jpg'
import fifth2 from './Assist/Event/Shell Distributor Confrence Krakow 2020/15.jpg'
import fifth3 from './Assist/Event/Shell Distributor Confrence Krakow 2020/16.jpg'
import fifth4 from './Assist/Event/Shell Distributor Confrence Krakow 2020/27.jpg'
import fifth5 from './Assist/Event/Shell Distributor Confrence Krakow 2020/28.jpg'





import sexth1 from './Assist/Event/Shell Distributor Confrence Cape Town 2013/DSC_0139.JPG'
import sexth2 from './Assist/Event/Shell Distributor Confrence Cape Town 2013/DSC_0146.JPG'
import sexth3 from './Assist/Event/Shell Distributor Confrence Cape Town 2013/DSC_0155.JPG'
import sexth4 from './Assist/Event/Shell Distributor Confrence Cape Town 2013/DSC_0238.JPG'
import sexth5 from './Assist/Event/Shell Distributor Confrence Krakow 2020/27.jpg'


import seven1 from './Assist/Event/Shell Distributor Confrence Athens 2015/1129915-MBD2_002.JPG'
import seven2 from './Assist/Event/Shell Distributor Confrence Athens 2015/1129915-MBD2_0013.JPG'
import seven3 from './Assist/Event/Shell Distributor Confrence Athens 2015/1129915-MBD2_0022.JPG'
import seven4 from './Assist/Event/Shell Distributor Confrence Athens 2015/1129915-MBD2_058.JPG'
import seven5 from './Assist/Event/Shell Distributor Confrence Athens 2015/1129915-MBD3_0025.JPG'






 const Data = [
    {
        id:5,
       
        title: 'Shell Distributor Confrence ',
        caption:'',
        city:'Krakow',
        date:'2020',
     
        img1:fifth1,
        img2:fifth2,
        img3:fifth3,
        img4:fifth4,
        img5:fifth5,

  
    },

    {
        id:4,
       
        title: 'Saudi Air Show ',
        caption:'',
        city:'Riyadh KSA',
        date:'2019',
     
        img1:fourth1,
        img2:fourth2,
        img3:fourth3,
        img4:fourth4,
        img5:fourth5,

  
    },
    {
        id:7,
       
        title: 'Shell Distributor Confrence ',
        caption:'',
        city:'Athens',
        date:'2015',
     
        img1:seven1,
        img2:seven2,
        img3:seven3,
        img4:seven4,
        img5:seven5,

  
    },

    {
        id:6,
       
        title: 'Shell Distributor Confrence ',
        caption:'',
        city:'Kape Town',
        date:'2013',
     
        img1:sexth1,
        img2:sexth2,
        img3:sexth3,
        img4:sexth4,
        img5:sexth5,

  
    },

    
    {
        id:1,
       
        title: 'Soom Official Launching Event Elaf Hotel Red Sea Jeddah',
        caption:'invited SAEI, Petromin and Aviation Customer for dinner and Launching event',
        city:'Jeddah KSA',
        date:'2012',
     
        img1:first1,
        img2:first2,
        img3:first3,
        img4:first4,
        img5:first5,

  
    },
      
  
    {
      id:2,
     
      title: '1st Annual Kindom Airport Aviation & Logistics Exhibition',
      caption:'Introduction of Soom Oil as Aviation Lubricants supplier in KSA',
      city:'Jeddah KSA',
      date:'2012',
   
      img1:second1,
      img2:second2,
      img3:second3,
      img4:second4,
      img5:second5,
      

  },






  {
    id:3,
   
    title: 'Abu Dhabi Formula 1 Event',
    caption:'Attending the Formula Event with Shell and Customer representative ',
    city:'Abu Dhabi UAE',
    date:'2012',
 
    img1:therid1,
    img2:therid2,
    img3:therid3,
    img4:therid4,
    img5:therid5,


},
    

      ]
  
  
  export default Data;