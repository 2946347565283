
//PISTON ENGINE OILS

import peo65 from './Assist/Proudcts/untitled folder/ASO100_80_65.jpg';
import pdfpe65 from './Assist/Proudcts/Piston Engine oil.pdf'

import peo80 from './Assist/Proudcts/untitled folder/ASO100_80_65.jpg';
import pdfpe80 from './Assist/Proudcts/Piston Engine oil.pdf'

import peo100 from './Assist/Proudcts/untitled folder/ASO100_80_65.jpg';
import pdfpe100 from './Assist/Proudcts/Piston Engine oil.pdf'

import peow15w50 from './Assist/Proudcts/untitled folder/W15W50_only.jpg';
import pdfpe10w50 from './Assist/Proudcts/Piston Engine oil.pdf'

import peow80 from './Assist/Proudcts/untitled folder/W80_W100_W120.jpg';
import pdfpew80 from './Assist/Proudcts/Piston Engine oil.pdf'

import peow100 from './Assist/Proudcts/untitled folder/W80_W100_W120.jpg';
import pdfpew100 from './Assist/Proudcts/Piston Engine oil.pdf'

import peow120 from './Assist/Proudcts/untitled folder/W80_W100_W120.jpg';
import pdfpew120 from './Assist/Proudcts/Piston Engine oil.pdf'

import peow80plus from './Assist/Proudcts/untitled folder/W80PLUS_W100PLUS.jpg';
import pdfpew80plus from './Assist/Proudcts/Piston Engine oil.pdf'

import peow100plus from './Assist/Proudcts/untitled folder/W80PLUS_W100PLUS.jpg';
import pdfpew100plus from './Assist/Proudcts/Piston Engine oil.pdf'


import peodisel from './Assist/Proudcts/ASO_DIESEL_ULTRA_1L.jpg';
import pdfpedisel from './Assist/Proudcts/Piston Engine oil.pdf'

import peo4plus from './Assist/Proudcts/ASO_SPORT PLUS 4_1L.jpg';
import pdfpe4plus from './Assist/Proudcts/Piston Engine oil.pdf'




//AVIATION GREASES





import g5 from './Assist/Proudcts/AS_GREASE_5_3KG.jpg';
import pdfG5 from './Assist/Proudcts/ASG5_TDS.pdf'

import g6 from './Assist/Proudcts/AS_GREASE_6_3KG.jpg';
import pdfG6 from './Assist/Proudcts/ASG6_TDS.pdf'

import g7 from './Assist/Proudcts/AS_GREASE_7_3KG.jpg';
import pdfG7 from './Assist/Proudcts/ASG7_TDS.pdf'

import g14 from './Assist/Proudcts/AS_GREASE_14_3KG.jpg';
import pdfG14 from './Assist/Proudcts/ASG14_TDS.pdf'

import g22 from './Assist/Proudcts/AS_GREASE_22_3KG.jpg';
import pdfG22 from './Assist/Proudcts/ASG22_TDS new book.pdf'

import g33 from './Assist/Proudcts/AS_GREASE_33_3KG.jpg';
import pdfG33 from './Assist/Proudcts/ASG33_TDS.pdf'

import g64 from './Assist/Proudcts/AS_GREASE_64_3KG.jpg';
import pdfG64 from './Assist/Proudcts/ASG33_TDS.pdf'


//TURBINE ENGINE OIL


import teo308 from './Assist/Proudcts/AS_TEO_308_1USQ.jpg';
import pdfTEO308 from './Assist/Proudcts/ASTO308_TDS.pdf'


import teo390 from './Assist/Proudcts/AS_TEO_390_1USQ.jpg';
import pdfTEO390 from './Assist/Proudcts/ASTO390_TDS.pdf'


import teo500 from './Assist/Proudcts/AS_TEO_500_1USQ.jpg';
import pdfTEO500 from './Assist/Proudcts/ASTO500_TDS.pdf'

import teo555 from './Assist/Proudcts/AS_TEO_555_1USQ.jpg';
import pdfTEO555 from './Assist/Proudcts/ASTO555_TDS.pdf'


import teo560 from './Assist/Proudcts/AS_TEO_560_1USQ.jpg';
import pdfTEO560 from './Assist/Proudcts/ASTO560_TDS.pdf'


import teo750 from './Assist/Proudcts/AS_TEO_750_1USQ.jpg';
import pdfTEO750  from './Assist/Proudcts/ASTO750_TDS.pdf'


import teoAS from './Assist/Proudcts/AS_TEO_Ascender_1USQ.jpg';
import pdfTEOAS  from './Assist/Proudcts/3cb5cfa3-1a81-4a8a-884a-989264e57028.pdf'
  //HYDRAULIC FLUIDS


import hsf31 from './Assist/Proudcts/Aeroshell fluid 31.jpg';
import pdfhsf31  from './Assist/Proudcts/ASF 71 & ASF 31.pdf'


import hsf41 from './Assist/Proudcts/aeroshell_fluid41.jpg';
import pdfhsf41  from './Assist/Proudcts/AeroShell Fluid 41.pdf'


//SPECIALIST FLUIDS

import asf3 from './Assist/Proudcts/aeroshell-fluid-3.jpg';
import pdfasf3  from './Assist/Proudcts/ASF3_TDS.pdf'


import asf12 from './Assist/Proudcts/fluid_12_.jpg';
import pdfasf12  from './Assist/Proudcts/ASF12_TDS.pdf'


import asf5ma from './Assist/Proudcts/Aeroshell_Fluid_5MA_.png';
import pdfasf5ma  from './Assist/Proudcts/ASF5MA_TDS.pdf'










const PItem = [


 // PISTON ENGINE OILS
 {

     
  img:peo65,
  title: 'AeroShell Oil 65 ',
  PDFfile:pdfpe65,
  category: 'PISTON ENGINE OILS'
},

{

     
  img:peo80,
  title: 'AeroShell Oil 80 ',
  PDFfile:pdfpe80,
  category: 'PISTON ENGINE OILS'
},

{

     
  img:peo100,
  title: 'AeroShell Oil 100 ',
  PDFfile:pdfpe100,
  category: 'PISTON ENGINE OILS'
},

{

     
  img:peow15w50,
  title: 'AeroShell Oil W15W50 ',
  PDFfile:pdfpe10w50,
  category: 'PISTON ENGINE OILS'
},

{

     
  img:peow80,
  title: 'AeroShell Oil W80 ',
  PDFfile:pdfpew80,
  category: 'PISTON ENGINE OILS'
},


{

     
  img:peow100,
  title: 'AeroShell Oil W100 ',
  PDFfile:pdfpew100,
  category: 'PISTON ENGINE OILS'
},


{

     
  img:peow120,
  title: 'AeroShell Oil W120 ',
  PDFfile:pdfpew120,
  category: 'PISTON ENGINE OILS'
},


{

     
  img:peow80plus,
  title: 'AeroShell Oil W80 PLUS ',
  PDFfile:pdfpew80plus,
  category: 'PISTON ENGINE OILS'
},

{

     
  img:peow100plus,
  title: 'AeroShell Oil W100 PLUS ',
  PDFfile:pdfpew100plus,
  category: 'PISTON ENGINE OILS'
},


{

     
  img:peodisel,
  title: 'AeroShell Oil Diesel Ultra ',
  PDFfile:pdfpedisel,
  category: 'PISTON ENGINE OILS'
},



{

     
  img:peo4plus,
  title: 'AeroShell Sport Plus 4 ',
  PDFfile:pdfpe4plus,
  category: 'PISTON ENGINE OILS'
},





 //AVIATION GREASES

    {

     
      img:g5,
      title: 'AeroShell Grease 5',
      PDFfile:pdfG5,
      category: 'AVIATION GREASES'
    },
    {
      img:g6,
      title: 'AeroShell Grease 6',
      PDFfile:pdfG6,
      category: 'AVIATION GREASES'
    },
    
    {
        img:g7,
        title: 'AeroShell Grease 7',
        PDFfile:pdfG7,
        category: 'AVIATION GREASES'
      },

      {
        img:g14,
        title: 'AeroShell Grease 14',
        PDFfile:pdfG14,
        category: 'AVIATION GREASES'
      },


      {
        img:g22,
        title: 'AeroShell Grease 22',
        PDFfile:pdfG22,
        category: 'AVIATION GREASES'
      },




      {
        img:g33,
        title: 'AeroShell Grease 33',
        PDFfile:pdfG33,
        category: 'AVIATION GREASES'
      },



      {
        img:g64,
        title: 'AeroShell Grease 64',
        PDFfile:pdfG64,
        category: 'AVIATION GREASES'
      },








      //TURBINE ENGINE OIL

      {
        img:teo308,
        title: 'AeroShell Turbine Oil 308',
        PDFfile:pdfTEO308,
        category: 'TURBINE ENGINE OILS'
      },



      {
        img:teo390,
        title: 'AeroShell Turbine Oil 390',
        PDFfile:pdfTEO390,
        category: 'TURBINE ENGINE OILS'
      },


      {
        img:teo500,
        title: 'AeroShell Turbine Oil 500',
        PDFfile:pdfTEO500,
        category: 'TURBINE ENGINE OILS'
      },

      {
        img:teo555,
        title: 'AeroShell Turbine Oil 555',
        PDFfile:pdfTEO555,
        category: 'TURBINE ENGINE OILS'
      },


      {
        img:teo560,
        title: 'AeroShell Turbine Oil 560',
        PDFfile:pdfTEO560,
        category: 'TURBINE ENGINE OILS'
      },



      {
        img:teo750,
        title: 'AeroShell Turbine Oil 750',
        PDFfile:pdfTEO750,
        category: 'TURBINE ENGINE OILS'
      },

      {
        img:teoAS,
        title: 'Aeroshell Ascender ',
        PDFfile:pdfTEOAS,
        category: 'TURBINE ENGINE OILS'
      },


      //HYDRAULIC FLUIDS



      {
        img:hsf31,
        title: 'AeroShell Fluid 31 ',
        PDFfile:pdfhsf31,
        category: 'HYDRAULIC FLUIDS'
      },

      {
        img:hsf41,
        title: 'AeroShell Fluid 41 ',
        PDFfile:pdfhsf41,
        category: 'HYDRAULIC FLUIDS'
      },




      //SPECIALIST FLUIDS


      {
        img:asf3,
        title: 'AeroShell Fluid 3 ',
        PDFfile:pdfasf3,
        category: 'SPECIALIST FLUIDS'
      },

      {
        img:asf12,
        title: 'AeroShell Fluid 12 ',
        PDFfile:pdfasf12,
        category: 'SPECIALIST FLUIDS'
      },

      {
        img:asf5ma,
        title: 'AeroShell Fluid 5M-A ',
        PDFfile:pdfasf5ma,
        category: 'SPECIALIST FLUIDS'
      },

  ]

  export default PItem


  export const CategoryList = [
    'ALL',
    'PISTON ENGINE OILS',
    'TURBINE ENGINE OILS',
    'AVIATION GREASES',
    'HYDRAULIC FLUIDS',
    'SPECIALIST FLUIDS'
  ]