import React from 'react'





function ProudctItem({PItem}) {
    return (
   <>

      
   
      <div className="fea-gd-vv text-center row mt-5 pt-3">
     
      {
           PItem && PItem.map(({img, title, caption ,PDFfile})  => 
                       
                       <div key={title} className="col-lg-4 col-md-6">	
                           <div className="float-top">

                              <img src={img} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
                               <div className="float-lt feature-gd">	
                                       <h3><p>{title}</p> </h3>
                                          
                                           <a className="btn-theme2" href={PDFfile} download >  download Pdf File </a>
                                       </div>
                                       
                                   </div><br /><br />
                       </div>
                       

            )
        }
        </div>
        </>
        )
    }


















export default ProudctItem