import React, { Component } from "react";
import  'react-router-dom';


import pa1 from './Assist/PDF/OneDrive-2021-01-31/aero-shell-for-boeing-aircrafts-1_page-0001.jpg'
import pa2 from './Assist/PDF/OneDrive-2021-01-31/aeroshell_for _airbus _aircrafts-1_page-0001.jpg'
import pa3 from './Assist/PDF/OneDrive-2021-01-31/aeroshell-product-spec-guide-w-nsn-num-a3-1_page-0001.jpg'
import pa1pdf from './Assist/PDF/OneDrive-2021-01-31/aero-shell-for-boeing-aircrafts.pdf'
import pa2pdf from './Assist/PDF/OneDrive-2021-01-31/aeroshell_for _airbus _aircrafts.pdf'
import pa3pdf from './Assist/PDF/OneDrive-2021-01-31/aeroshell-product-spec-guide-w-nsn-num-a3.pdf'



class Papplcation extends Component {
    state={
       
    }
 render(){
    

   
    return (

       



        <div className="w3l-contact-breadcrum-4" id="Media">
       
  <div className="breadcrum-bg">
    <div className="container py-5">
      <h2 className="my-3">Product Applications</h2>
    
    </div>
  </div>



  

<div className="news py-5">
    <div className="container py-md-3">
        

          <div className="fea-gd-vv text-center row mt-5 pt-3">
  
                     

  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">
      <br></br>
         <img src={pa1} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>AEROSHELL FOR BOEING AIRCRAFTS </p> </h4>  
                  <br>

                  </br>
                  <a className="btn-theme2" href={pa1pdf} download >   Download PDF </a> 
  
                  </div> 
              </div>



              
  </div>
  
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">
<br></br>
         <img src={pa2} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p>AEROSHELL FOR AIRBUS AIRCRAFTS </p> </h4>  
                  <br>

                  </br>  
                  <a className="btn-theme2" href={pa2pdf} download >   Download PDF </a> 
 
                  </div> 
              </div>



              
  </div>
 
  
  <div  className="col-lg-4 col-md-6">	
      <div className="float-top">
<br></br>
         <img src={pa3} className="img-responsive" alt="Prpoudcts img" style={{ width: '100%', maxHeight: '300px' }}/>
          <div className="float-lt feature-gd">	
                  <h4><p> AEROSHELL PROUDCT GUIDE</p> </h4>   
                  <br>
                      
                  </br> 
                  <a className="btn-theme2" href={pa3pdf} download >   Download PDF </a> 

                  </div> 
              </div>



              
  </div>
      
          

       
  
  

</div>

  </div>
    
	</div>


</div>

    );
}
}
export default Papplcation;
