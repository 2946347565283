import React, { Component } from "react";


import Carousel from 'react-bootstrap/Carousel'


class MediaItem extends Component {

    
render(){
    const { img1 ,img2 ,img3 ,img4 ,img5 , title, caption,date,city} = this.props.dat;
    
  

    return (


      <>


<div className="news py-5">
   
        <div className="blog-grids row mt-5">
            <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                
                    <figure>
                    <Carousel >
  <Carousel.Item>
    <img 
    style={{ width: '100%', maxHeight: '700px' }}
      className="d-block w-100"
      src={img1} 
      alt="First slide"
    />
    
  </Carousel.Item>
  <Carousel.Item>
    <img 
    style={{ width: '100%', maxHeight: '700px' }}
      className="d-block w-100"
      src={img2}
      alt="Third slide"
    />

   
  </Carousel.Item>
  <Carousel.Item>
    <img 
    style={{ width: '100%', maxHeight: '700px' }}
      className="d-block w-100"
      src={img3}
      alt="Third slide"
    />

    
  </Carousel.Item>

  <Carousel.Item>
    <img 
    style={{ width: '100%', maxHeight: '700px' }}
      className="d-block w-100"
      src={img4}
      alt="Third slide"
    />

    
  </Carousel.Item>

  <Carousel.Item>
    <img 
    style={{ width: '100%', maxHeight: '700px' }}
      className="d-block w-100"
      src={img5}
      alt="Third slide"
    />

    
  </Carousel.Item>
</Carousel>
                    </figure>
                
                <div className="blog-info">
                    <h3>{title}</h3>
                    <p> {caption}</p>
                    <ul>
                    <li><span className="fa-map-marked-alt"></span>{city}</li>
                        <li><span className="fa fa-calendar mr-2"></span>{date}</li>
                    </ul>
                </div>
            </div>
            </div>
           
	</div>
</div>
      
      
      
         <div>
        
        
         </div>


        </>

    )
}
}
export default MediaItem;