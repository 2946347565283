import React from 'react'
import pic from '../components/Assist/images/Picture1.png';
import pic2 from '../components/Assist/images/ssl.jpeg';

import { Link } from "react-router-dom";

function Footer() {

return(

<div className="w3l-footer-29-main" id="footer">
  <div className="footer-29">
    <div className="grids-forms-1 pb-5">
<div className="container">
  <div className="grids-forms">
      
    </div>
  </div>
  </div>
      <div className="container pt-5">
        
          <div className="d-grid grid-col-4 footer-top-29">
              <div className="footer-list-29 footer-1">
                  
                  <div className="main-social-footer-29">
                    <h6 className="footer-title-29">Social Links</h6>
                     
                      <a  href = "https://www.linkedin.com/company/soom-oil" className="linkedin"> 
                      <span className="fa fa-linkedin"></span> </a>
                  </div>
              </div>
              <div className="footer-list-29 footer-2 ">

              

           
              
            

              
              <img src={pic2} style={{ width: 120 }} className="img mt-3" alt="" />
             
              </div>
              <div className="footer-list-29 footer-3 ">

              

           
              
            

              <img src={pic} style={{ width: 350 }} className="img mt-3" alt="" />
              
             
              </div>
              <div className="footer-list-29 footer-4 ">
                  <ul>
                      <h6 className="footer-title-29">Quick Links</h6>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/About">About Us</Link></li>
                      <li><Link to="/Proudcts">Proudcts</Link></li>
                      <li><Link to="/Media"> Media Center</Link></li>
                      <li><Link to="/Contact">Contact Us</Link></li>
                  </ul>
              </div>
          </div>
          <div className="bottom-copies text-center">
              <p className="copy-footer-29">© 2021 Soomoil. All rights reserved  </p>
               
          </div>
      </div>
  </div>
   </div>
   
	 
    )
}

export default Footer
